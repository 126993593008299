import { createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
// import { Button } from '@mui/material';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';

import { choiceActions } from '../../../../../../../../_actions';
import { useLocation, useNavigate } from 'react-router-dom';

import React, { useEffect, useState } from 'react';
import CurrencySelectionModal from './CurrencySelectionModal';
import PageHeader from '../../../../../PageHeader';
import { DocumentsSubmitted } from '../DocumentsSubmitted';
import { DocumentVerified } from '../DocumentVerfied';
import SetupFailed from './Setupfailed';
import { settingSelector } from '../../../../../../../../_reducers/setting.reducer';
import { getUser } from '../../../../../../../../_helpers';
import { settingActions } from '../../../../../../../../_actions/setting.actions';
// import { toast } from '@team-monite/ui-widgets-react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { selectAllData } from '../../../../../../../../_reducers/choiceBank.reducer';

const AccountSetup = ({ setSetpNumberAuth, path, updateStep }: any) => {
  const entities = useSelector(
    (state: any) => state.getChoiceTypesReducer.data
  );
  const businessDetails = useSelector(
    (state: any) => state.getBusinessDetailsReducer.data
  );
  // Check if the entities array is not empty
  const hasEntities = entities && entities.length > 0;
  const [selectedType, setSelectedType] = useState('');
  const [selectedTypeValue, setSelectedTypeValue] = useState(
    'Select business type'
  );
  const [showCurrency, setShowCurrency] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const selectedCurrency = location.state?.selectedMethod;
  const [status, setstatus] = useState('waiting_for_submission');
  const [isLoading, setIsLoading] = useState(false);
  const dataEntity: any = useSelector((state) => settingSelector(state));
  // const bussinessDetails: any = useSelector((state) => selectAllData(state));
  const [typevalue, setType] = useState('');
  const [renderEntity, setRenderEntity] = useState(true);
  const [renderEntity_two, setRenderEntity_two] = useState(true);
  const [userDetail, setUserDetail] = useState(false);
  const [render, setRender] = useState(true);
  const [users, setUsers] = useState<any>();
  const [loader, setLoader] = useState(false)
  const user = getUser()?.data ?? '';

  // Use the useDispatch hook to dispatch actions
  const dispatch = useDispatch();
  console.log(selectedType, 'selectedType');

  // Use the useEffect hook to dispatch the action when the component mounts
  useEffect(() => {
    dispatch(choiceActions.getEntityTypes() as any);
    dispatch(choiceActions.getBusinessDetails(user?.user_id) as any);
    setSelectedType(businessDetails?.data?.businessType);
    console.log('*businessDetails', businessDetails);
  }, []);

  useEffect(() => {
    if (businessDetails) {
      setSelectedType(businessDetails?.businessType);

      if (businessDetails?.businessType == 2) {
        setSelectedTypeValue('Limited Liability Company');
      } else if (businessDetails?.businessType == 1) {
        setSelectedTypeValue('Sole Proprietorship');
      } else if (businessDetails?.businessType == 3) {
        setSelectedTypeValue('Partnership');
      }
    }
  }, [businessDetails]);

  React.useEffect(() => {
    console.log('Getting here');
    setIsLoading(true);
    if (
      renderEntity_two &&
      dataEntity?.GETENTITYDOCUMENT_SUCCESS !== undefined
    ) {
      console.log(
        'dataEntity list',
        'dataEntity',
        dataEntity.GETENTITYDOCUMENT_SUCCESS.data
      );
      if (
        !!dataEntity.GETENTITYDOCUMENT_SUCCESS.data &&
        dataEntity.GETENTITYDOCUMENT_SUCCESS.data.length !== 0
      ) {
        if (typeof dataEntity.GETENTITYDOCUMENT_SUCCESS.data == 'object') {
          var GetData = dataEntity.GETENTITYDOCUMENT_SUCCESS.data;

          console.log('****** statusS:', status);

          setType(GetData.document_type);
          setUserDetail(true);
          console.log('*businessDetails', businessDetails);
          setstatus(GetData.status);

          setSelectedType(businessDetails?.businessType);

          if (businessDetails?.businessType == 2) {
            setSelectedTypeValue('Limited Liability Company');
          } else if (businessDetails?.businessType == 1) {
            setSelectedTypeValue('Sole Proprietorship');
          } else if (businessDetails?.businessType == 3) {
            setSelectedTypeValue('Partnership');
          }

          console.log('**selectedTypeValue', selectedTypeValue);

          // const savedBusinessType = entities?.find(
          //   (entity: any) => entity.key === businessDetails?.businessType
          // );

          // if (savedBusinessType) {
          //   setSelectedTypeValue(savedBusinessType.value);
          //   console.log('**savedBusinessType', savedBusinessType);
          //   setSelectedType(savedBusinessType.key);
          //   console.log('**savedBusinessKey', savedBusinessType);
          // }
        }
      }

      // if (!!bussinessDetails.BUSINESS_DETAILS_SUCCESS.data) {
      //   var businessDetails = bussinessDetails.BUSINESS_DETAILS_SUCCESS.data;

      //   console.log('****** businessDetails:', businessDetails);
      // }
    }
    setIsLoading(false);
  }, [renderEntity_two, renderEntity, dataEntity.GETENTITYDOCUMENT_SUCCESS]);

  // useEffect(() => {
  //   // const foundElement = entities.find(element => element.key === keyToFind);
  //   const savedBusinessType = entities.find(
  //     (entity: any) => entity.key === businessDetails?.businessType
  //   );

  //   if (savedBusinessType) {
  //     setSelectedTypeValue(savedBusinessType.value);
  //     console.log('**savedBusinessType', savedBusinessType);
  //     setSelectedType(savedBusinessType.key);
  //     console.log('**savedBusinessKey', savedBusinessType);
  //   }
  // }, [entities, businessDetails]);

  // useEffect(() => {
  //   if (status === 'waiting_for_submission') {
  //     navigate('/settings/document-upload');
  //   }
  // }, [status, navigate]);

  React.useEffect(() => {
    // console.log('thiis is running');
    if (getUser && render) {
      setIsLoading(true);
      setRender(false);
      setUsers(getUser);
      dispatch(settingActions.getEntityDocuments() as any);
      // dispatch(choiceActions.getStoreOnboardingInfo(user?.entity?.id) as any);

      setIsLoading(false);
    }
  }, []);

  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });

  const handleNext = (e: any) => {
    e.preventDefault();

    // if (!businessDetails) {
    setLoader(true)
    dispatch(
      choiceActions.saveBusinessDetails(
        {
          businessType: selectedType,
          userId: user?.user_id,
          otpType: 'email',
        },
        () => {
          // toast.success('Business type saved ');
          if (path == '/v2/document-upload') {
            setSetpNumberAuth(1);
            if (typeof updateStep == 'function') {
              updateStep(1, true, navigate(path, {
                state: {
                  selectedType: selectedType,
                  selectedCurrency: selectedCurrency,
                  fromAccountSetup: true,
                },
              }));
            }
          } else {
            navigate(path, {
              state: {
                selectedType: selectedType,
                selectedCurrency: selectedCurrency,
                fromAccountSetup: true,
              },
            })
          }
        },0
      ) as any
    );
    // }
    setTimeout(() => {
      setLoader(false)
    }, 3000)

  };

  const handleBack = (e: any) => {
    e.preventDefault();
    setShowCurrency(true);
  };
  return (
    <>
      {status !== 'waiting_for_submission' && (
        <div className="profile">
          {status === 'waiting_for_approval' && (
            <DocumentsSubmitted
              onSave={() => {
                navigate('/');
              }}
            />
          )}
          {status === 'approved' &&
            users?.data?.entity?.status !== 'VERIFIED' && (
              <DocumentsSubmitted
                onSave={() => {
                  navigate('/');
                }}
              />
            )}
          {status === 'approved' &&
            users?.data?.entity?.status === 'VERIFIED' && (
              <DocumentVerified
                onSave={() => {
                  navigate('/');
                }}
              />
            )}
          {status === 'rejected' && <SetupFailed />}
        </div>
      )}
      {status == 'waiting_for_submission' && (
        <div className="tags" style={{ height: '100vh' }}>
          {/* <PageHeader title="Account Setup" /> */}
          {/* <Button
          id="button_back"
          style={{ color: '#025141', marginLeft: '48px' }}
          onClick={handleBack}
          className="payment-journey-container__detailbox__white-btn"
        >
          <ThemeProvider theme={theme}>
            <ArrowBackIcon style={{ fontSize: '19px', marginRight: '10px' }} />
          </ThemeProvider>
          Go Back
        </Button> */}
          <div className="create_api_box">
            <div className="inside_sub_box">
              <p className="heading_create_api">
                Please complete your account setup{' '}
              </p>
              <div className={'input_box'}>
                <Form.Select
                  style={{ fontSize: '13px' }}
                  className="complete-profile__SELECT"
                  aria-label="Default select example"
                  value={selectedType}
                  onChange={(e) => {
                    // console.log('e.target.value', e.target.value);
                    setSelectedType(e.target.value);
                  }}
                >
                   <option key="empty" value="">
                      Type of Organisation
                    </option>
                  {/* {selectedTypeValue == '' && (
                    <option key="empty" value="">
                      Type of Organisation
                    </option>
                  )}

                  {selectedTypeValue && (
                    <option key={selectedType} value={selectedTypeValue}>
                      {selectedTypeValue}
                    </option>
                  )} */}
                  {hasEntities
                    ? entities.map((element: any) => {
                      return (
                        <option key={element.id} value={element.key}>
                          {element.value}
                        </option>
                      );
                    })
                    : 'data not found'}
                </Form.Select>
              </div>
              <div style={{ display: "flex", justifyContent: 'center' }}>
                <Button

                  className="complete-profile__btn2"
                  disabled={!selectedType}
                  style={{ width: "50%", fontSize: "14px" }}
                  onClick={(e: any) => {
                    handleNext(e);
                  }}
                >
                  {loader == true && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Proceed
                </Button>
              </div>
            </div>
          </div>
          {showCurrency && (
            <CurrencySelectionModal
              show={showCurrency}
              onClose={() => {
                setShowCurrency(false);
                // setShowBalanceGrid(true);
              }}
              onSave={(el: boolean) => {
                setShowCurrency(false);
                // setCereat_Teabal(true)
              }}
              onconfirm={(el: boolean) => {
                setShowCurrency(false);
                // setIsOpen(true)
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

export default AccountSetup;
