import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';
import '../Settings.scss';
import { getBase64 } from '../../../../../../../../../_helpers/common';
import { choiceActions } from '../../../../../../../../../_actions/choice.action';
import { Form, InputGroup } from 'react-bootstrap';
import { settingSelector } from '../../../../../../../../../_reducers/setting.reducer';
import { ErrorMessage } from 'formik';
import DoneIcon from "@mui/icons-material/Done";
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import UploadIcon from '../UploadIcon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../Loader';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { CountryDropdown } from 'react-country-region-selector';
import { Avatar } from '@mui/material';

const LimitedLiability = ({
  formik,
  certificateFile,
  certificateLabel,
  crLabel,
  crFile,
  memorandumLabel,
  memorandumFile,
  handleImage,
  loader,
  fileSize,
  resolutionLabel,
  resolutionFile,
  kraLabel,
  handleRemoveImage,
  licenseLabel,
  licenseFile,
  ultimateBeneficiaryLabel,
  ultimateBeneficiaryFile,
  articleOfAssociationLabel,
  articleOfAssociationFile,
  kraFile,
  setpsubmit
}: any) => {
  const industries = useSelector(
    (state: any) => state.getIndustriesReducer.data
  );

  const operationModes = useSelector(
    (state: any) => state.getBusinessOperationModesReducer.data
  );

  const [paymentacodiyan, setPaymentacodiyan] = useState<boolean>(false);
  const [kraAccordion, setKraAccordion] = useState<boolean>(false);

  const [renderSubmit, setRenderSubmit] = useState(true);
  const dispatch = useDispatch();
  const dataEntity: any = useSelector((state) => settingSelector(state));

  React.useEffect(() => {
    if (
      renderSubmit &&
      dataEntity?.SUBMITENTITYDOCUMENT_SUCCESS !== undefined
    ) {
      console.log(
        'Succesfully Sumbit Entity list',
        dataEntity?.SUBMITENTITYDOCUMENT_SUCCESS
      );
      setRenderSubmit(false);
    }
  }, [dataEntity, renderSubmit]);

  const [dataaccodiyan, setDataccodiyan] = useState<boolean>(false);
  // const [chack_cox_data, setChack_cox_data] = useState(false);
  const [invoicefile, setinvoicefile] = useState('Upload supporting documents');

  useEffect(() => {
    console.log('dispatching action');
    dispatch(choiceActions.getIndustries() as any);
    dispatch(choiceActions.getOperatingModes() as any);
  }, [dispatch]);

  const paymentAccordionHasErrors = () => {
    const fieldsToCheck = [
      'businessName',
      'businessAddress',
      'businessIndustry',
      'businessCerNum',
      'operatingModeOther',
      'businessCertificate',
      'cr',
      'memorandum',
      'resolution',
    ];
    if (setpsubmit == true) {

    return fieldsToCheck.some((field) => formik?.errors?.[field]);
    }
  };

  const taxAccordionHasErrors = () => {
    const fieldsToCheck = ['kraPin', 'kraPinCertificate'];
    if (setpsubmit == true) {

    return fieldsToCheck.some((field) => formik?.errors?.[field]);
    }
  };

  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  console.log(formik, "formikformik");

  // const imageUrl =
  //   'https://myniobiawsbucket.s3.eu-central-1.amazonaws.com/documents/frontID-6ab96d5d-8af3-4e1f-8895-7069fb56f1f32023-08-22.png';
  return (
    <>
      <p className="complete-profile__businesstypo">Business details</p>
      <div className="calss_accordion_contenar">
        <div
          className="maian_adaat_listtts"
          onClick={() => {
            if (paymentacodiyan === false) {
              setPaymentacodiyan(true);
              setDataccodiyan(false);
              setKraAccordion(false);
            } else {
              setPaymentacodiyan(false);
            }
          }}
        >
          <ThemeProvider theme={theme}>
            <div
              className={` ${!paymentAccordionHasErrors()
                ? 'avayar_text'
                : 'avayar_text_error'
                }`}
            >
              <Avatar className="Avatar_fast">
                {!!formik.values.businessName &&
                  !!formik.values.businessAddress &&
                  !!formik.values.country &&
                  !!formik.values.businessIndustry &&
                  !!formik.values.businessCerNum &&
                  // !!formik.values.specify&&
                  !!formik.values.businessCertificate &&
                  !!formik.values.cr &&
                  !!formik.values.memorandum &&
                  !!formik.values.resolution ?
                  <DoneIcon /> :
                  "1"}</Avatar>
              <p className="accordion_p_data_contean">Company details</p>
            </div>
            {paymentAccordionHasErrors() && (
              <ReportProblemIcon style={{ color: 'rgb(255, 101, 101)' }} />
            )}
            {!paymentAccordionHasErrors() ? (
              <>
                {paymentacodiyan === false ? (
                  <ExpandMoreIcon style={{ color: '#1D1D1D' }} />
                ) : (
                  <ExpandLessIcon style={{ color: '#1D1D1D' }} />
                )}{' '}
              </>
            ) : (
              ''
            )}
          </ThemeProvider>
        </div>

        {paymentacodiyan === true ? (
          <>
            <div className="input-form-wrapper dot">
              <label>Business name</label>
              <InputGroup className="doc-input-wrapper ">
                <Form.Control
                  name="businessName"
                  value={formik.values.businessName}
                  onChange={formik.handleChange}
                  type="text"
                  isInvalid={
                    formik.touched.businessName && formik.errors.businessName
                  }
                />
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="businessName" component="div" />
              </p>
            </div>
            <div className="input-form-wrapper dot">
              <label>Business address</label>
              <InputGroup className="doc-input-wrapper">
                <Form.Control
                  name="businessAddress"
                  value={formik.values.businessAddress}
                  onChange={formik.handleChange}
                  type="text"
                  isInvalid={
                    formik.touched.businessAddress &&
                    Boolean(formik.errors.businessAddress)
                  }
                />
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="businessAddress" component="div" />
              </p>
            </div>
            <div className="input-form-wrapper dot">
              <label>Country</label>
              <CountryDropdown
                id="CountryDropdown_class"
                value={formik.values.country}
                onChange={(e, event) => {
                  // setCountryError(!!e ? false : true);
                  // setCountry(e);
                  formik.handleChange(event);
                }}
                name="country"
                classes={
                  'form-text form-control' +
                  (formik.errors.country && formik.touched.country
                    ? ' is-invalid'
                    : '')
                }
              />
              <p className="error-message">
                {' '}
                <ErrorMessage name="country" component="div" />
              </p>
            </div>

            {/* <div className="input-form-wrapper dot">
              <label>Website</label>
              <InputGroup className="doc-input-wrapper">
                <Form.Control
                  name="website"
                  value={formik.values.website}
                  onChange={formik.handleChange}
                  type="text"
                  isInvalid={
                    formik.touched.website && Boolean(formik.errors.website)
                  }
                />
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="website" component="div" />
              </p>
            </div> */}
            <div className="input-form-wrapper dot">
              <label>Business activity</label>
              <InputGroup className="doc-input-wrapper">
                <Form.Select
                  name="businessIndustry"
                  value={formik.values.businessIndustry}
                  onChange={(e) => {
                    // formik.handleChange(e)
                    // const findData = industries.find((data: any) => data.id == e.target.value)
                    // formik.setFieldValue('business_industry_name', findData?.value)
                    // ;
                    formik.handleChange(e)
                    const findData = industries.find((data: any) => data.id == e.target.value)
                    if (e.target.value == '21') {
                      formik.setFieldValue('business_industry_name', '');
                      formik.setFieldValue("specifyIndustry",'')
                    } else {
                      formik.setFieldValue('business_industry_name', findData?.value);
                      formik.setFieldValue("specifyIndustry",'')
                    }
                  }}
                  isInvalid={
                    formik.touched.businessIndustry &&
                    Boolean(formik.errors.businessIndustry)
                  }
                >
                  <option>Select industry</option>
                  {Array.isArray(industries)
                    ? industries?.map((element: any) => {
                      return (
                        <option key={element.id} value={element.key}>
                          {element.value}
                        </option>
                      );
                    })
                    : 'data not found'}
                </Form.Select>
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="businessIndustry" component="div" />
              </p>
            </div>
            {formik.values.businessIndustry == '21' ?
              <div className="input-form-wrapper">
                <InputGroup className="doc-input-wrapper">
                  <Form.Control
                    name="business_industry_name"
                    value={formik.values.business_industry_name}
                    onChange={(e: any) => {
                      if(formik.values.businessIndustry == '21'){
                      formik.setFieldValue("specifyIndustry", e.target.value)
                      }else{
                        formik.setFieldValue("specifyIndustry",'')
                      }
                      formik.handleChange(e)
                    }
                    }
                    type="text"
                    isInvalid={
                      formik.touched.business_industry_name && formik.errors.business_industry_name
                    }
                  />
                </InputGroup>
                <p className="error-message">
                  {' '}
                  <ErrorMessage name="business_industry_name" component="div" />
                </p>
              </div> : ""
            }
            {/* <div className="input-form-wrapper dot">
              <label>Business operating mode</label>
              <InputGroup className="doc-input-wrapper">
                <Form.Select
                  name="operatingMode"
                  value={formik.values.operatingMode}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.operatingMode &&
                    Boolean(formik.errors.operatingMode)
                  }
                >
                  <option>Business operating modes</option>
                  {Array.isArray(operationModes)
                    ? operationModes?.map((element: any) => {
                        return (
                          <option key={element.id} value={element.key}>
                            {element.value}
                          </option>
                        );
                      })
                    : 'data not found'}
                </Form.Select>
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="operatingMode" component="div" />
              </p>
            </div> */}
            <div className="input-form-wrapper dot">
              <label>Business registration number</label>
              <InputGroup className="doc-input-wrapper">
                <Form.Control
                  name="businessCerNum"
                  value={formik.values.businessCerNum}
                  onChange={formik.handleChange}
                  type="text"
                  isInvalid={
                    formik.touched.businessCerNum &&
                    Boolean(formik.errors.businessCerNum)
                  }
                />
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="businessCerNum" component="div" />
              </p>
            </div>

            {formik.values.operatingMode === 5 && (
              <div className="input-form-wrapper dot">
                <label>Specify other operating mode</label>
                <InputGroup className="doc-input-wrapper">
                  <Form.Control
                    name="operatingModeOther"
                    value={formik.values.specify}
                    onChange={formik.handleChange}
                    type="text"
                    isInvalid={
                      formik.touched.specify && Boolean(formik.errors.specify)
                    }
                  />
                </InputGroup>
                <p className="error-message">
                  {' '}
                  <ErrorMessage name="businessAddress" component="div" />
                </p>
              </div>
            )}
            <div className="flax_data_list">
              <Tooltip title="Upload your documents in PDF, DOC, DOCX, JPEG, or PNG format. Max size: 5MB per file.">
                <div style={{ width: '100%', display: 'block' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label className="upload-label">
                      Upload registration certificate
                    </label>
                    <div className="required_marker"></div>
                  </div>
                  <label
                    style={{ padding: '5px 0px 0px 0px' }}
                    className="complete-profile__upload mb-0"
                  >
                    <div
                      // id='afafafsdaFfsf'
                      className="complete-profile__upload__label"
                    //  style={{ cursor: 'not-allowed' }}
                    >
                      <span className="complete-profile__upload__txt">
                      {formik.values.businessCertificate ? 
                      certificateFile :
                      "Upload notarized documents if entity is outside Kenya."}
                      </span>
                      {/* {certificateFile === certificateLabel ? (
                      <UploadIcon />
                    ) : (
                      <CloseIcon />
                    )} */}
                    </div>
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg, .png, .docx, .doc"
                      name="businessCertificate"
                      onChange={(e) => {
                        handleImage(e, formik);
                      }}
                    />
                    {5 * 1024 * 1024 <= fileSize ? (
                      <div className="error-message">
                        File size too large, max file size is 5 Mb
                      </div>
                    ) : null}
                    <p className="error-message">
                      {' '}
                      {formik.errors.businessCertificate ? (
                        <ErrorMessage
                          name="businessCertificate"
                          component="div"
                        />
                      ) : null}
                    </p>
                  </label>
                </div>
              </Tooltip>
              {certificateFile === certificateLabel ? (
                <div className="upload-icon">
                  {' '}
                  <UploadIcon />
                </div>
              ) : (
                <div
                  className="upload-icon"
                  onClick={(e) => {
                    // remove_data(
                    //   groupOneName[i].KRN_certi_new
                    // );
                    // handleRemoveImage('businessCertificate');
                    handleRemoveImage(e, 'businessCertificate', formik);
                  }}
                >
                  <CloseIcon />
                </div>
              )}
            </div>
            <div style={{ height: '15px' }}></div>
            <div className="flax_data_list">
              <Tooltip title="Upload Documents (File-size: Max 5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                <div style={{ width: '100%', display: 'block' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label className="upload-label">
                      Upload register of directors & shareholders
                    </label>
                    <div className="required_marker"></div>
                  </div>
                  <label
                    style={{ padding: '5px 0px 0px 0px' }}
                    className="complete-profile__upload mb-0"
                  >
                    <div
                      // id='afafafsdaFfsf'
                      className="complete-profile__upload__label"
                    //  style={{ cursor: 'not-allowed' }}
                    >
                      <span className="complete-profile__upload__txt">
                        {formik.values?.businessDocs?.data != null &&
                          formik.values?.businessDocs?.data?.data?.cr
                            ?.file_name != null
                          ? formik.values?.businessDocs?.data?.data?.cr
                            ?.file_name
                          : crFile}
                      </span>
                      {/* {crFile === crLabel ? <UploadIcon /> : <CloseIcon />} */}
                    </div>
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg, .png"
                      name="cr"
                      onChange={(e) => {
                        handleImage(e, formik);
                      }}
                    />
                    {1024 * 1024 <= fileSize ? (
                      <div className="error-message">
                        File size too large, max file size is 1 Mb
                      </div>
                    ) : null}
                    <p className="error-message">
                      {' '}
                      {formik.errors.cr ? (
                        <ErrorMessage name="cr" component="div" />
                      ) : null}
                    </p>
                  </label>
                </div>
              </Tooltip>
              {crFile === crLabel ? (
                <div className="upload-icon">
                  {' '}
                  <UploadIcon />
                </div>
              ) : (
                <div
                  className="upload-icon"
                  onClick={(e) => {
                    // remove_data(
                    //   groupOneName[i].KRN_certi_new
                    // );
                    // handleRemoveImage('cr');
                    handleRemoveImage(e, 'cr', formik);
                  }}
                >
                  <CloseIcon />
                </div>
              )}
            </div>
            <div style={{ height: '15px' }}></div>
            <div className="flax_data_list">
              <Tooltip title="Upload Documents (File-size: Max 1 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                <div style={{ width: '100%', display: 'block' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label className="upload-label">
                      Upload memorandum of association
                    </label>
                    <div className="required_marker"></div>
                  </div>
                  <label
                    style={{ padding: '5px 0px 0px 0px' }}
                    className="complete-profile__upload mb-0"
                  >
                    <div
                      // id='afafafsdaFfsf'
                      className="complete-profile__upload__label"
                    //  style={{ cursor: 'not-allowed' }}
                    >
                      <span className="complete-profile__upload__txt">
                        {formik.values?.businessDocs?.data != null &&
                          formik.values?.businessDocs?.data?.data?.memorandum
                            ?.file_name != null
                          ? formik.values?.businessDocs?.data?.data?.memorandum
                            ?.file_name
                          : memorandumFile}
                      </span>
                      {/* {memorandumFile === memorandumLabel ? (
                      <UploadIcon />
                    ) : (
                      <CloseIcon />
                    )} */}
                    </div>
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg, .png"
                      name="memorandum"
                      onChange={(e) => {
                        handleImage(e, formik);
                      }}
                    />
                    {1024 * 1024 <= fileSize ? (
                      <div className="error-message">
                        File size too large, max file size is 1 Mb
                      </div>
                    ) : null}
                    <p className="error-message">
                      {' '}
                      {formik.errors.memorandum ? (
                        <ErrorMessage name="memorandum" component="div" />
                      ) : null}
                    </p>
                  </label>
                </div>
              </Tooltip>
              {memorandumFile === memorandumLabel ? (
                <div className="upload-icon">
                  {' '}
                  <UploadIcon />
                </div>
              ) : (
                <div
                  className="upload-icon"
                  onClick={(e) => {
                    // remove_data(
                    //   groupOneName[i].KRN_certi_new
                    // );
                    // handleRemoveImage('memorandum');
                    handleRemoveImage(e, 'memorandum', formik);
                  }}
                >
                  <CloseIcon />
                </div>
              )}
            </div>
            <div style={{ height: '15px' }}></div>
            <div className="flax_data_list">
              <Tooltip title="Upload Documents (File-size: Max 1 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                <div style={{ width: '100%', display: 'block' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label className="upload-label">
                      Upload board resolution
                    </label>
                    <div className="required_marker"></div>
                  </div>
                  <label
                    style={{ padding: '5px 0px 0px 0px' }}
                    className="complete-profile__upload mb-0"
                  >
                    <div
                      // id='afafafsdaFfsf'
                      className="complete-profile__upload__label"
                    //  style={{ cursor: 'not-allowed' }}
                    >
                      <span className="complete-profile__upload__txt">
                        {formik.values?.businessDocs?.data != null &&
                          formik.values?.businessDocs?.data?.data?.resolution
                            ?.file_name != null
                          ? formik.values?.businessDocs?.data?.data?.resolution
                            ?.file_name
                          : resolutionFile}
                      </span>
                      {/* {resolutionFile === resolutionLabel ? (
                      <UploadIcon />
                    ) : (
                      <CloseIcon />
                    )} */}
                    </div>
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg, .png"
                      name="resolution"
                      onChange={(e) => {
                        handleImage(e, formik);
                      }}
                    />
                    {1024 * 1024 <= fileSize ? (
                      <div className="error-message">
                        File size too large, max file size is 1 Mb
                      </div>
                    ) : null}
                    <p className="error-message">
                      {' '}
                      {formik.errors.resolution ? (
                        <ErrorMessage name="resolution" component="div" />
                      ) : null}
                    </p>
                  </label>
                </div>
              </Tooltip>
              {resolutionFile === resolutionLabel ? (
                <div className="upload-icon">
                  {' '}
                  <UploadIcon />
                </div>
              ) : (
                <div
                  className="upload-icon"
                  onClick={(e) => {
                    // handleRemoveImage('resolution');
                    handleRemoveImage(e, 'resolution', formik);
                  }}
                >
                  <CloseIcon />
                </div>
              )}
            </div>
            <a
              href="/BoardResolutionTemplatefinal.pdf"
              download="boardResolutionTemplate.pdf"
              style={{ fontSize: '11px', textDecoration: 'underline' }}
            >
              Download board resolution template
            </a>
            {/* <div className="flax_data_list">
              <Tooltip
                className="doc-input-wrapper"
                title="Upload Documents (File-size: Max 1 MB. File-type: jpeg, png or pdf)"
              >
                <div style={{ width: '100%', display: 'block' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label className="upload-label">
                      Upload license (if applicable)
                    </label>
                     <div className="required_marker"></div> 
                  </div>
                  <label
                    style={{ padding: '5px 0px 0px 0px' }}
                    className="complete-profile__upload mb-0"
                  >
                    <div
                      // id='afafafsdaFfsf'
                      className="complete-profile__upload__label"
                      //  style={{ cursor: 'not-allowed' }}
                    >
                      <span className="complete-profile__upload__txt">
                        {formik.values?.businessDocs?.data != null &&
                        formik.values?.businessDocs?.data?.data?.license
                          ?.file_name != null
                          ? formik.values?.businessDocs?.data?.data?.license
                              ?.file_name
                          : licenseFile}
                      </span>
                    </div>
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg, .png"
                      // disabled={submitted}
                      name="license"
                      onChange={(e) => {
                        handleImage(e, formik);
                      }}
                    />
                    {1024 * 1024 <= fileSize ? (
                      <div className="error-message">
                        File size too large, max file size is 1 Mb
                      </div>
                    ) : null}
                    <p className="error-message">
                      {' '}
                      {formik.errors.license ? (
                        <ErrorMessage name="license" component="div" />
                      ) : null}
                    </p>
                  </label>
                </div>
              </Tooltip>
              {licenseFile === licenseLabel ? (
                <div className="upload-icon">
                  {' '}
                  <UploadIcon />
                </div>
              ) : (
                <div
                  className="upload-icon"
                  onClick={(e) => {
                    // remove_data(
                    //   groupOneName[i].KRN_certi_new
                    // );
                    handleRemoveImage('license', formik);
                    console.log('remove');
                  }}
                >
                  <CloseIcon />
                </div>
              )}
            </div> */}
          </>
        ) : (
          ''
        )}
      </div>

      <div className="calss_accordion_contenar">
        <div
          className="maian_adaat_listtts"
          onClick={() => {
            if (kraAccordion === false) {
              setKraAccordion(true);
              setDataccodiyan(false);
              setPaymentacodiyan(false);
            } else {
              setKraAccordion(false);
            }
          }}
        >
          <ThemeProvider theme={theme}>
            <div
              className={` ${!taxAccordionHasErrors() ? 'avayar_text' : 'avayar_text_error'
                }`}
            >
              <Avatar className="Avatar_fast">
                {!!formik.values.kraPin &&
                  !!formik.values.kraPinCertificate  ?
                  <DoneIcon /> :
                  "2"}</Avatar>
              {/* <Avatar className="Avatar_fast">{!!amont_data && !!vat_data && selectedList.length > 0 ? <DoneIcon /> : "2"}</Avatar> */}
              <p className="accordion_p_data_contean">Tax Details</p>
            </div>
            {taxAccordionHasErrors() && (
              <ReportProblemIcon style={{ color: 'rgb(255, 101, 101)' }} />
            )}
            {!taxAccordionHasErrors() ? (
              <>
                {kraAccordion === false ? (
                  <ExpandMoreIcon style={{ color: '#1D1D1D' }} />
                ) : (
                  <ExpandLessIcon style={{ color: '#1D1D1D' }} />
                )}
              </>
            ) : (
              ''
            )}
          </ThemeProvider>
        </div>

        {kraAccordion === true ? (
          <>
            <div className="input-form-wrapper dot">
              <label> Company tax identification number</label>
              <InputGroup className="doc-input-wrapper">
                <Form.Control
                  name="kraPin"
                  value={formik.values.kraPin}
                  onChange={formik.handleChange}
                  type="text"
                  isInvalid={
                    formik.touched.kraPin && Boolean(formik.errors.kraPin)
                  }
                />
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="kraPin" component="div" />
              </p>
            </div>
            <div className="flax_data_list">
              <Tooltip title="Upload Documents (File-size: Max 1 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                <div style={{ width: '100%', display: 'block' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label className="upload-label">
                      Upload Tax certificate
                    </label>
                    <div className="required_marker"></div>
                  </div>
                  <label
                    style={{ padding: '5px 0px 0px 0px' }}
                    className="complete-profile__upload mb-0"
                  >
                    <div
                      // id='afafafsdaFfsf'
                      className="complete-profile__upload__label"
                    //  style={{ cursor: 'not-allowed' }}
                    >
                      <span className="complete-profile__upload__txt">
                        {formik.values?.businessDocs?.data != null &&
                          formik.values?.businessDocs?.data?.data
                            ?.kraPinCertificate?.file_name != null
                          ? formik.values?.businessDocs?.data?.data
                            ?.kraPinCertificate?.file_name
                          : kraFile}
                      </span>
                    </div>
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg, .png"
                      name="kraPinCertificate"
                      onChange={(e) => {
                        handleImage(e, formik);
                      }}
                    />
                    {1024 * 1024 <= fileSize ? (
                      <div className="error-message">
                        File size too large, max file size is 1 Mb
                      </div>
                    ) : null}
                    <p className="error-message">
                      {' '}
                      {formik.errors.kraPinCertificate ? (
                        <ErrorMessage
                          name="kraPinCertificate"
                          component="div"
                        />
                      ) : null}
                    </p>
                  </label>
                </div>
              </Tooltip>
              {kraFile === kraLabel ? (
                <div className="upload-icon">
                  {' '}
                  <UploadIcon />
                </div>
              ) : (
                <div
                  className="upload-icon"
                  onClick={(e) => {
                    // remove_data(
                    //   groupOneName[i].KRN_certi_new
                    // );
                    // handleRemoveImage('kraPinCertificate');
                    handleRemoveImage(e, 'kraPinCertificate', formik);
                  }}
                >
                  <CloseIcon />
                </div>
              )}
            </div>
          </>
        ) : (
          ''
        )}
      </div>
      {loader && <Loader />}
    </>
  );
};

export default LimitedLiability;
