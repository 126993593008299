import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';
import { To } from 'history';
import React from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Unauthorized } from '../_constants/ErrorMessage';

export const userActions = {
  login,
  logout,
  register,
  updatePassword,
  forgotPassword,
  verifyToken,
  changePassword,
  getOtp,
  registerByInvite,
  sendOtp,
  loginOtpVerify,
  ResendOtp,
  LoginToken,
  saveCurrencies,
  registerv2
};

function login(user: any, from: To) {
  return (
    dispatch: (arg0: {
      type: string;
      user?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request({ user }));
    userService
      .login(user)
      .then((response) => {
        return response.json();
      })
      .then(
        (user) => {
          if (user.success) {
            var datalist = new Date();
            var adadaaga: boolean = true;
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('token', user.data.token);
            localStorage.setItem('user_verification', user.data.user_flag);
            localStorage.setItem('Bulkdata', JSON.stringify([]));
            localStorage.setItem('Bulkdatatwo', JSON.stringify([]));
            localStorage.setItem('Bulk_Payment', JSON.stringify([]));
            localStorage.setItem('tokentwo', JSON.stringify(datalist));
            sessionStorage.setItem('logged', 'true');
            dispatch(success(user));
            dispatch(alertActions.success('Login successful'));
            toast.success('Login successful', { duration: 8000 });
            history.push(from);
            if (user.data.verified) {
              if (user.data.hasEntity) {
                window.location.href = '/dashboard';
              } else {
                window.location.href = '/v2/account-setup';
              }
            } else {
              window.location.href = '/verify-email';
            }
          } else {
            dispatch(failure(user.message));
            dispatch(alertActions.error(user.message));
            toast.error(user.message, { duration: 8000 });
          }
        },
        (error) => {
          toast.error(Unauthorized, { duration: 8000 });
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(user: any) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user: any) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}
function LoginToken(user: any, data: any, typeenv: any, LoginFun: any) {
  return (
    dispatch: (arg0: {
      type: string;
      user?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request({ user }));
    userService
      .LoginToken(user, data)
      .then((response) => {
        return response.json();
      })
      .then(
        (user) => {
          if (user.success) {
            var datalist = new Date();
            var adadaaga: boolean = true;
            localStorage.setItem('user', JSON.stringify(user));
            LoginFun(JSON.stringify(user), typeenv)
            console.log(typeenv,"typeenvtypeenv");
            
            localStorage.setItem(
              'apiMode',
             typeenv
            );
            localStorage.setItem('token', user.data.token);
            localStorage.setItem('user_verification', user.data.user_flag);
            localStorage.setItem('Bulkdata', JSON.stringify([]));
            localStorage.setItem('Bulkdatatwo', JSON.stringify([]));
            localStorage.setItem('Bulk_Payment', JSON.stringify([]));
            localStorage.setItem('tokentwo', JSON.stringify(datalist));
            sessionStorage.setItem('logged', 'true');
            dispatch(success(user));
            dispatch(alertActions.success('Login successful'));
            toast.success('Login successful', { duration: 8000 });
            if (user.data.verified) {
              if (user.data.hasEntity) {
                window.location.reload()
              } else {
                window.location.href = '/v2/account-setup';
              }
            } else {
              window.location.href = '/verify-email';
            }
          } else {
            dispatch(failure(user.message));
            dispatch(alertActions.error(user.message));
            toast.error(user.message, { duration: 8000 });
          }
        },
        (error) => {
          toast.error(Unauthorized, { duration: 8000 });
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(user: any) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user: any) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}
function logout(data: any, message: any) {
  userService
    .logout()

    .then((response) => {
      return response.json();
    })
    .then(
      (user) => {
        const logoutData = data == 'auto' ? '/login?data=auto' : '/login';
        if (message == "Logged out successfully") {
          toast.success(message, { duration: 8000 });
        } else if (message == "Your session has expired. Kindly login again.") {
          toast.error(message, { duration: 8000 });
        }
        localStorage.removeItem('user');
        localStorage.removeItem('Bulkdata');
        localStorage.removeItem('Bulkdatatwo');
        localStorage.removeItem('Bulk_Payment');
        localStorage.removeItem('token');
        localStorage.removeItem('tokentwo');
        localStorage.removeItem('user_verification');
        console.log(message, "messagemessage156");
        window.location.href = logoutData;
      },
      (error) => {
        const logoutData = data == 'auto' ? '/login?data=auto' : '/login';
        // if(message=="Logged out successfully"){
        //   toast.success(message, { duration: 8000 });
        // }else{
        //   toast.error(message, { duration: 8000 });
        // }
        localStorage.removeItem('user');
        localStorage.removeItem('Bulkdata');
        localStorage.removeItem('Bulkdatatwo');
        localStorage.removeItem('Bulk_Payment');
        localStorage.removeItem('token');
        localStorage.removeItem('user_verification');
        localStorage.removeItem('tokentwo');

        window.location.href = logoutData;
      }
    );
  return { type: userConstants.LOGOUT };
}

function register(URLApi: any, user: any, path: any) {
  return (
    dispatch: (arg0: {
      type: string;
      user?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(user));

    userService
      .register(URLApi, user)
      .then((response) => {
        return response.json();
      })
      .then(
        (user) => {
          if (user.success) {
            if (URLApi === process.env.REACT_APP_STAGING_API_URL) {
              userService
                .sendEmail(user.data)
                .then((response) => {
                  return response.json();
                })
            }
            if (!path) {
              var datalist = new Date();
              dispatch(success(user));
              dispatch(alertActions.success('Registration successful'));
              localStorage.setItem('userproduction', JSON.stringify(user));
              localStorage.setItem('tokenproduction', user.data.token);
              localStorage.setItem('user_verification', user.data.user_flag);
              console.log('User: ', user);
              localStorage.setItem('Bulkdata', JSON.stringify([]));
              localStorage.setItem('Bulkdatatwo', JSON.stringify([]));
              localStorage.setItem('Bulk_Payment', JSON.stringify([]));

              localStorage.setItem('tokentwo', JSON.stringify(datalist));
              localStorage.setItem('newUser', 'true');
              sessionStorage.setItem('logged', 'true');
            } else {
              var datalist = new Date();
              dispatch(success(user));
              dispatch(alertActions.success('Registration successful'));
              localStorage.setItem('user', JSON.stringify(user));
              localStorage.setItem('token', user.data.token);
              localStorage.setItem('user_verification', user.data.user_flag);
              console.log('User: ', user);
              localStorage.setItem('Bulkdata', JSON.stringify([]));
              localStorage.setItem('Bulkdatatwo', JSON.stringify([]));
              localStorage.setItem('Bulk_Payment', JSON.stringify([]));

              localStorage.setItem('tokentwo', JSON.stringify(datalist));
              localStorage.setItem('newUser', 'true');
              sessionStorage.setItem('logged', 'true')
              window.location.href = path
            }

          } else {
            dispatch(failure(user.message));
            dispatch(alertActions.error(user.message));
            if (user.message == 'Validation errors') {
              var fieldarray = [
                'email',
                'first_name',
                'last_name',
                'password',
                'confirm_password',
                'country_code',
                'mobile',
              ];
              fieldarray.map((field: any) => {
                if (!!user.data[field]) {
                  console.log(user?.data[field], "!!user.data[field]");

                  user.data[field].map((i: string) => {
                    toast.error(i, { duration: 8000 });
                  });
                }
              });
            }
          }
        },
        (error) => {
          toast.error(Unauthorized, { duration: 8000 });
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(user: any) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user: any) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}
function registerv2(user: any, path: any, api: any, setLoading: any) {
  return (
    dispatch: (arg0: {
      type: string;
      user?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    setLoading(true)
    dispatch(request(user));
    userService
      .registerV2(user, api)
      .then((response) => {
        return response.json();
      })
      .then(
        (user) => {
          if (user.success) {
            var datalist = new Date();
            dispatch(success(user));
            dispatch(alertActions.success('Registration successful'));
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('token', user.data.token);
            localStorage.setItem('user_verification', user.data.user_flag);
            console.log('User: ', user);
            localStorage.setItem('Bulkdata', JSON.stringify([]));
            localStorage.setItem('Bulkdatatwo', JSON.stringify([]));
            localStorage.setItem('Bulk_Payment', JSON.stringify([]));
            localStorage.setItem(
              'apiMode',
              'production'
            );
            localStorage.setItem('tokentwo', JSON.stringify(datalist));
            sessionStorage.setItem('logged', 'true');
            localStorage.setItem("registerStep", '1')
            setLoading(false)
            window.location.href = path;
          } else {
            dispatch(failure(user.message));
            dispatch(alertActions.error(user.message));
            setLoading(false)
            if (user.message == 'Validation errors') {
              var fieldarray = [
                'email',
                'first_name',
                'last_name',
                'password',
                'confirm_password',
                'country_code',
                'mobile',
              ];
              fieldarray.map((field: any) => {
                if (!!user.data[field]) {
                  console.log(user?.data[field], "!!user.data[field]");

                  user.data[field].map((i: string) => {
                    toast.error(i);
                  });
                }
              });
            }
          }
        },
        (error) => {
          toast.error(Unauthorized);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(user: any) {
    return { type: userConstants.REGISTER_V2_REQUEST, user };
  }
  function success(user: any) {
    return { type: userConstants.REGISTER_V2_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: userConstants.REGISTER_V2_FAILURE, error };
  }
}
function updatePassword(user: any) {
  return (
    dispatch: (arg0: {
      type: string;
      user?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(user));

    userService
      .updatePassword(user)
      .then((response) => {
        return response.json();
      })
      .then(
        (user) => {
          if (user.success) {
            dispatch(success(user));
            dispatch(alertActions.success('Password Updated successfully'));
            toast.success('Password Updated successfully', { duration: 8000 });
            window.location.href = '/settings/team';
          } else {
            dispatch(failure(user.message));
            dispatch(alertActions.error(user.message));
            if (user.message == 'Validation errors') {
              var fieldarray = ['password', 'confirm_password', 'old_password'];
              fieldarray.map((field: any) => {
                if (!!user.data[field]) {
                  user.data[field].map((i: string) => {
                    toast.error(i, { duration: 8000 });
                  });
                }
              });
            }
          }
        },
        (error) => {
          toast.error(Unauthorized, { duration: 8000 });
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(user: any) {
    return { type: userConstants.UPDATEPASSWARD_REQUEST, user };
  }
  function success(user: any) {
    return { type: userConstants.UPDATEPASSWARD_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: userConstants.UPDATEPASSWARD_FAILURE, error };
  }
}

function forgotPassword(user: any) {
  return (
    dispatch: (arg0: {
      type: string;
      user?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(user));

    userService
      .forgotPassword(user)
      .then((response) => {
        return response.json();
      })
      .then(
        (user) => {
          if (user.success) {
            dispatch(success(user));
            dispatch(alertActions.success('Forgot password successfully'));
          } else {
            dispatch(failure(user.message));
            dispatch(alertActions.error(user.message));
            if (user.message == 'Validation errors') {
              var fieldarray = ['email'];
              fieldarray.map((field: any) => {
                if (!!user.data[field]) {
                  user.data[field].map((i: string) => {
                    toast.error(i, { duration: 8000 });
                  });
                }
              });
            }
            toast.error(user.message, { duration: 8000 });
          }
        },
        (error) => {
          toast.error(Unauthorized, { duration: 8000 });

          //alert(error.toString());
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(user: any) {
    return { type: userConstants.FORGOTPASSWARD_REQUEST, user };
  }
  function success(user: any) {
    return { type: userConstants.FORGOTPASSWARD_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: userConstants.FORGOTPASSWARD_FAILURE, error };
  }
}

function verifyToken(user: any) {
  return (
    dispatch: (arg0: {
      type: string;
      user?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(user));

    userService
      .verifyToken(user)
      .then((response) => {
        return response.json();
      })
      .then(
        (user) => {
          if (user.success) {
            dispatch(success(user));
            dispatch(alertActions.success('Verify Token successfully'));
          } else {
            dispatch(failure(user.message));
            dispatch(alertActions.error(user.message));
            if (user.message == 'Validation errors') {
              var fieldarray = ['token'];
              fieldarray.map((field: any) => {
                if (!!user.data[field]) {
                  user.data[field].map((i: string) => {
                    toast.error(i, { duration: 8000 });
                  });
                }
              });
            }
            window.location.href = '/login';
          }
        },
        (error) => {
          toast.error(Unauthorized, { duration: 8000 });
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(user: any) {
    return { type: userConstants.VERIFYTOKEN_REQUEST, user };
  }
  function success(user: any) {
    return { type: userConstants.VERIFYTOKEN_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: userConstants.VERIFYTOKEN_FAILURE, error };
  }
}

function changePassword(user: any) {
  return (
    dispatch: (arg0: {
      type: string;
      user?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(user));

    userService
      .changePassword(user)
      .then((response) => {
        return response.json();
      })
      .then(
        (user) => {
          if (user.success) {
            dispatch(success(user));
            dispatch(alertActions.success('Password Change successfully'));
            toast.success('Password Change successfully', { duration: 8000 });
            window.location.href = '/login';
          } else {
            dispatch(failure(user.message));
            dispatch(alertActions.error(user.message));
            if (user.message == 'Validation errors') {
              var fieldarray = ['password', 'confirm_password', 'old_password'];
              fieldarray.map((field: any) => {
                if (!!user.data[field]) {
                  user.data[field].map((i: string) => {
                    toast.error(i, { duration: 8000 });
                  });
                }
              });
            }

            //alert(user.message);
            //window.location.reload();
          }
        },
        (error) => {
          toast.error(Unauthorized, { duration: 8000 });

          //alert(error.toString());
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(user: any) {
    return { type: userConstants.CHANGEPASSWARD_REQUEST, user };
  }
  function success(user: any) {
    return { type: userConstants.CHANGEPASSWARD_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: userConstants.CHANGEPASSWARD_FAILURE, error };
  }
}
function sendOtp(datalist: any) {
  return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
    userService.sendOtp(datalist)
      .then(data => {
        return data.json()
      })
      .then(response => {
        if (response.success) {
          toast.success(response.message)
          dispatch(success(response))
        } else {
          toast.error(response.message)
          dispatch(failure(response))
        }

      },
        error => {
          dispatch(failure(error.toString()))
          dispatch(alertActions.error(error.message))
        }
      )
  }

  function request() { return { type: userConstants.SEND_OTP_REQUEST } }
  function success(data: any) { return { type: userConstants.SEND_OTP_SUCCESS, data } }
  function failure(error: string) { return { type: userConstants.SEND_OTP_FAILURE, error } }
}
function ResendOtp(datalist: any) {
  return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
    userService.ResendOtp(datalist)
      .then(data => {
        return data.json()
      })
      .then(response => {
        if (response.success) {
          toast.success(response.message)
          dispatch(success(response))
        } else {
          toast.error(response.message)
          dispatch(failure(response))
        }

      },
        error => {
          dispatch(failure(error.toString()))
          dispatch(alertActions.error(error.message))
        }
      )
  }

  function request() { return { type: userConstants.SEND_OTP_REQUEST } }
  function success(data: any) { return { type: userConstants.SEND_OTP_SUCCESS, data } }
  function failure(error: string) { return { type: userConstants.SEND_OTP_FAILURE, error } }
}
function loginOtpVerify(datalist: any, path: any) {
  return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
    userService.loginOtpVerify(datalist)
      .then(data => {
        return data.json()
      })
      .then(user => {
        if (user.success) {
          var datalist = new Date();
          var adadaaga: boolean = true;
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('token', user.data.token);
          localStorage.setItem('user_verification', user.data.user_flag);
          localStorage.setItem('Bulkdata', JSON.stringify([]));
          localStorage.setItem('Bulkdatatwo', JSON.stringify([]));
          localStorage.setItem('Bulk_Payment', JSON.stringify([]));
          localStorage.setItem('tokentwo', JSON.stringify(datalist));
          localStorage.setItem(
            'apiMode',
            'production'
          );
          sessionStorage.setItem('logged', 'true');
          dispatch(success(user));
          dispatch(alertActions.success('Login successful'));
          toast.success('Login successful', { duration: 8000 });
          if (user.data.verified) {
            if (!user?.data?.entity || user?.data?.entity?.status == "UNVERIFIED" || user?.data?.entity?.status == "NEW") {
              console.log(user?.data?.completed_steps, user, "user?.data?.completed_steps");

              if (user?.data?.completed_steps == 1) {
                window.location.href = '/v2/account-setup'
              } else if (user?.data?.completed_steps == 2) {
                window.location.href = '/v2/document-upload'
              } else if (user?.data?.completed_steps == 3) {
                window.location.href = '/v2/document-upload?step-3'
              } else if (user?.data?.completed_steps == 4) {
                window.location.href = '/v2/entity'
              } else if (user?.data?.completed_steps == 5) {
                window.location.href = '/v2/currency'
              } else if (user?.data?.completed_steps == 6) {
                window.location.href = '/v2/reviews'
              } else if (user?.data?.completed_steps == 7) {
                window.location.href = '/v2/verify-identity'
              } else {
                window.location.href = '/v2/account-setup-completed'
              }
              // window.location.href = path;
            } else if (user?.data?.entity?.status == "VERIFIED") {
              window.location.href = '/dashboard';
            }
          }
          // history.push(from);
          // if (user.data.verified) {
          //   if (user?.data?.entity?.status == "UNVERIFIED" || user?.data?.entity?.status == "NEW") {
          //     window.location.href = path;
          //   } else if (user.data.hasEntity) {
          //     window.location.href = '/dashboard';
          //   } else {
          //     window.location.href = path;
          //   }
          // } else {
          //   window.location.href = path;
          // }
        } else {
          dispatch(failure(user));
          dispatch(alertActions.error(user.message));
          toast.error(user.message, { duration: 8000 });
        }

      },
        error => {
          dispatch(failure(error.toString()))
          dispatch(alertActions.error(error.message))
        }
      )
  }

  function request() { return { type: userConstants.LOGIN_OTP_VERIFY_REQUEST } }
  function success(data: any) { return { type: userConstants.LOGIN_OTP_VERIFY_SUCCESS, data } }
  function failure(error: string) { return { type: userConstants.LOGIN_OTP_VERIFY_FAILURE, error } }
}
function getOtp(props: any) {
  return (
    dispatch: (arg0: { type: any; data?: any; message?: any }) => void
  ) => {
    // dispatch(request())

    userService
      .getOtp()
      .then((data) => {
        return data.json();
      })
      .then(
        (response) => {
          dispatch(success(response.data));
          if (props == true) {
            toast.success("Our apologies, this Group account does not support transactions with third-party merchants. Instead, consider opting for a Direct M-Pesa transfer.", { duration: 15000 })
          }
          toast.success(response.message, { duration: 8000 });
        },
        (error) => {
          toast.error(Unauthorized, { duration: 8000 });

          //dispatch(failure(error.toString()))
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function success(data: any) {
    return { type: userConstants.GETOTP_SUCCESS, data };
  }
}

function registerByInvite(user: any) {
  return (
    dispatch: (arg0: {
      type: string;
      user?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(request(user));

    userService
      .registerByInvite(user)
      .then((response) => {
        return response.json();
      })
      .then(
        (user) => {
          if (user.success) {

            var datalist = new Date();
            dispatch(success(user));
            dispatch(alertActions.success('Registration successful'));
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('token', user.data.token);
            localStorage.setItem('user_verification', user.data.user_flag);
            localStorage.setItem('Bulkdata', JSON.stringify([]));
            localStorage.setItem('Bulkdatatwo', JSON.stringify([]));
            localStorage.setItem('Bulk_Payment', JSON.stringify([]));
            localStorage.setItem('tokentwo', JSON.stringify(datalist));
            localStorage.setItem(
              'apiMode',
              'production'
            );
            sessionStorage.setItem('logged', 'true');
            // history.push('/verify-email')
            window.location.href = '/dashboard';
          } else {
            dispatch(failure(user.message));
            dispatch(alertActions.error(user.message));
            if (user.message == 'Validation errors') {
              var fieldarray = [
                'email',
                'first_name',
                'last_name',
                'email',
                'password',
                'confirm_password',
                'country_code',
                'mobile',
              ];
              fieldarray.map((field: any) => {
                if (!!user.data[field]) {
                  user.data[field].map((i: string) => {
                    toast.error(i, { duration: 8000 });
                  });
                }
              });
            }

            //alert(user.message);
            //window.location.reload();
          }
        },
        (error) => {
          toast.error(Unauthorized, { duration: 8000 });

          //alert(error.toString());
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(user: any) {
    return { type: userConstants.REGISTER_INVITE_REQUEST, user };
  }
  function success(user: any) {
    return { type: userConstants.REGISTER_INVITE_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: userConstants.REGISTER_INVITE_FAILURE, error };
  }
}
function saveCurrencies(user: any, Newigesan: any, setLoading: any) {
  return (
    dispatch: (arg0: {
      type: string;
      user?: any;
      message?: any;
      error?: any;
    }) => void
  ) => {
    setLoading(true)
    dispatch(request(user));

    userService
      .saveCurrencies(user)
      .then((response) => {
        return response.json();
      })
      .then(
        (user) => {
          if (user.success) {
            dispatch(success(user));
            Newigesan()
            setLoading(false)
            // dispatch(alertActions.success('Forgot password successfully'));
          } else {
            dispatch(failure(user.message));
            dispatch(alertActions.error(user.message));
            setLoading(false)
            toast.error(user.message);
          }
        },
        (error) => {
          toast.error(Unauthorized);
          setLoading(false)
          //alert(error.toString());
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(user: any) {
    return { type: userConstants.SAVE_CURRENCIES_REQUEST, user };
  }
  function success(user: any) {
    return { type: userConstants.SAVE_CURRENCIES_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: userConstants.SAVE_CURRENCIES_FAILURE, error };
  }
}